<script>
import { VMoney } from 'v-money'

export default {
  name: 'MoneyField',
  components: {
    Icon: () => import('@/components/general/Icon'),
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  directives: {
    money: VMoney
  },

  props: {
    placeholder: {
      type: String,
      default: null
    },

    label: {
      type: String,
      default: null
    },

    floatingLabel: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    value: null,
    validation: {
      type: Object,
      default: function () {
        return {}
      }
    },

    hint: {
      type: String,
      default: null
    },

    prependIcon: {
      type: String,
      default: null
    },

    prependLabel: {
      type: String,
      default: null
    },

    appendIcon: {
      type: String,
      default: null
    },

    showCheck: {
      type: Boolean,
      default: false
    },

    dark: {
      type: Boolean,
      default: false
    },

    prefix: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      directiveActive: false,
      isFocused: false,
      config: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2
      },

      mutableConfig: null,
      mutableValue: null
    }
  },

  computed: {
    hasValue () {
      return (this.value != null && this.value.toString().length > 0) || this.placeholder !== null
    },

    hasValidation () {
      return Object.keys(this.validation).length
    },

    isValid () {
      return this.validation.$error === false && this.validation.$dirty && this.showCheck
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (newValue) {
        this.directiveActive = !!this.value
        const formatted = newValue ? this.format(newValue) : null

        if (formatted !== this.mutableValue) {
          this.mutableValue = formatted
        }
      }
    },

    directiveActive () {
      this.mutableConfig = this.directiveActive ? this.config : null
      this.$refs.input.focus()
    }
  },

  created () {
    if (this.prefix) {
      this.config.prefix = this.prefix
    }

    if (this.$n(9999.99, 'currency') !== '') {
      const arrayNumberFormat = this.$n(9999.99, 'currency').match(/\d+[.|,]+\d+[.|,]\d+/)[0].match(/\D/g)

      this.config.thousands = arrayNumberFormat[0]
      this.config.decimal = arrayNumberFormat[1]
    }
  },

  methods: {
    updateValue (value) {
      typeof this.validation.$touch === 'function' && this.validation.$touch()
      this.$emit('input', this.unformat(value))
    },

    updateFocus () {
      this.isFocused = true
    },

    updateBlur () {
      this.isFocused = false
    },

    unformat (value) {
      const negative = value.indexOf('-') >= 0 ? -1 : 1
      const numbers = value.toString().replace(/\D+/g, '') || '0'
      const currency = this.numbersToCurrency(numbers)

      return parseFloat(currency) * negative
    },

    format (value) {
      if (typeof value === 'number') {
        value = value.toFixed(this.fixed(this.config.precision))
      }

      const negative = value.indexOf('-') >= 0 ? '-' : ''

      const numbers = this.onlyNumbers(value)
      const currency = this.numbersToCurrency(numbers, this.config.precision)
      const parts = this.toStr(currency).split('.')
      let integer = parts[0]
      const decimal = parts[1]

      integer = this.addThousandSeparator(integer, this.config.thousands)

      return this.config.prefix + negative + this.joinIntegerAndDecimal(integer, decimal, this.config.decimal) + this.config.suffix
    },

    onlyNumbers (input) {
      return this.toStr(input).replace(/\D+/g, '') || '0'
    },

    toStr (value) {
      return value ? value.toString() : ''
    },

    addThousandSeparator (integer, separator) {
      return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`)
    },

    joinIntegerAndDecimal (integer, decimal, separator) {
      return decimal ? integer + separator + decimal : integer
    },

    numbersToCurrency (numbers) {
      const exp = Math.pow(10, this.config.precision)
      const float = parseFloat(numbers) / exp

      return float.toFixed(this.fixed(this.config.precision))
    },

    fixed () {
      return this.between(0, this.config.precision, 20)
    },

    between (min, n, max) {
      return Math.max(min, Math.min(n, max))
    }
  }
}
</script>

<template>
  <div
    class="form-item"
    :class="{ 'has-error': validation.$error, 'has-value': hasValue, 'has-focus': isFocused, 'is-disabled': disabled, 'is-valid': isValid, 'has-floating-label': floatingLabel, 'has-prepend': prependIcon || prependLabel, 'theme-dark': dark }"
  >
    <label
      v-if="label"
      class="form-label"
      :for="'input' + _uid"
    >{{ label }}</label>
    <div
      v-if="prependIcon || prependLabel"
      class="form-input-prepend"
    >
      <span
        v-if="prependLabel"
        class="form-input-prepend-label"
      >{{ prependLabel }}</span>
      <Icon
        v-if="prependIcon"
        :name="prependIcon"
        wrapper
        class="form-input-prepend-icon"
      />
    </div>
    <div class="form-input-wrapper">
      <input
        :id="'input' + _uid"
        ref="input"
        v-money="mutableConfig"
        class="form-input"
        spellcheck="false"
        type="tel"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :value="mutableValue"
        @input="updateValue($event.target.value)"
        @focus="updateFocus()"
        @blur="updateBlur()"
      >
      <div class="form-input-append">
        <Icon
          v-if="appendIcon"
          :name="appendIcon"
          wrapper
        />
        <Icon
          v-if="isValid"
          name="check"
          wrapper
        />
      </div>
    </div>
    <div
      v-if="hint || hasValidation"
      class="form-input-details"
    >
      <span
        v-if="hint && !validation.$error"
        class="form-input-hint"
      >{{ hint }}</span>
      <ValidationMessage
        :validation="validation"
        currency
      />
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/form.css"></style>

<style src="@/assets/styles/themes/default/tooltip.css"></style>
